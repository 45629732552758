<template>
  <div class="d-flex flex-column" style="min-height: inherit">
    <div class="main">
      <Suspense>
        <router-view />
      </Suspense>
    </div>
    <footer>
      <AppFooter></AppFooter>
    </footer>
  </div>
</template>
<script>
import "./appkit/assets/appkit/vendor.min.css";
import "./appkit/assets/appkit/appkit.min.css";
import { defineComponent } from 'vue'
import AppFooter from "@components/general/AppFooter.vue";
export default defineComponent({
  name: "RCBAPP",
  components: {
    AppFooter
  }
})
</script>


<style>
@import "./assets/style/global.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}

#nav {
  padding: 30px;
}
#nav a {
    font-weight: bold;
    color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

.main {
  flex-grow: 1;
}

footer {
  margin-top: 20px;
  min-height: 150px;
  box-shadow: 0 -.2rem .25rem 0 rgba(0, 0, 0, .15);
}

</style>
