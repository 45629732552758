<script lang="ts">
import {defineComponent} from "vue";
import openSourceLicenses from '@/assets/licenses.txt'

export default defineComponent({
  name: "AppFooter",
  components: {},
  data() {
    return {
      fileUrl: openSourceLicenses
    }
  }
})
</script>
<template>
  <div class="app-footer">
    <div>
      © 2024 PwC Österreich GmbH Wirtschaftsprüfungsgesellschaft. Alle Rechte vorbehalten. "PwC Österreich" bezieht sich auf die PwC Österreich GmbH Wirtschaftsprüfungsgesellschaft oder eine ihrer Tochtergesellschaften, die jeweils eine eigene Rechtspersönlichkeit darstellen. Für weitere Details siehe <a href="https://www.pwc.at/de/impressum.html">https://www.pwc.at/de/impressum.html</a>. "PwC" bezieht sich auf das PwC-Netzwerk und/oder eine oder mehrere seiner Mitgliedsfirmen, die jeweils eine eigene Rechtspersönlichkeit darstellen. Weitere Einzelheiten finden Sie unter pwc.com/structure.
    </div>
    <div class="app-footer-links">
      <a :href="fileUrl" download="Open-Source-License.txt">Open-Source Lizenzen</a>
    </div>
  </div>
</template>
<style scoped>
  .app-footer {
    font-size: 14px;
    padding: 40px;
  }

  .app-footer-links {
    margin-top: 10px;
  }
</style>